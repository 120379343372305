<template>
    <v-card width="430" class="overflow-x-hidden">
        <v-container fluid  >
            <section class="d-flex flex-wrap align-center mb-5">
                <v-spacer />
                <section class="text-right">
                    <v-btn icon @click="$emit('close')">
                        <v-icon small>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </section>
            </section>

            <FormSelectObject
                :label="'TYPE OF PAYMENT'"
                :value.sync="type_of_payment"
                :items="type_of_payments"
            />

            <div class="error--text f11" v-if="type_of_payment === ''">Please pick the type of payment from the options.</div>

        </v-container>
        <v-container fluid class="d-flex flex-row justify-space-evenly mt-3">
            <v-btn dense text @click="$emit('back')">
                Back
            </v-btn>
            <v-spacer />
            <v-btn outlined dense color="primary" :disabled="type_of_payment === ''" :loading="proceedToPaymentLoading" @click="proceed">
                Continue
            </v-btn>
        </v-container>
    </v-card>
</template>

<script>

export default {
    props: {
        proceedToPaymentLoading: {
            default: false,
            type: Boolean
        }
    },
    data: () => ({
        type_of_payment: '',
        type_of_payments: [
            { text: '-', value: '' },
            { text: 'LINKBIZ PORTAL', value: 'linkbiz_portal' },
            { text: 'CASH DEPOSIT', value: 'cash_deposit' },
            { text: 'CASH-CSC CASHIER', value: 'cash-csc_cashier' },
            { text: 'CHECK DEPOSIT', value: 'check_deposit' },
            { text: 'CHECK-CSC CASHIER', value: 'check-csc_cashier' },
            { text: 'FUND TRANSFER', value: 'fund_transfer' },
        ]
    }),
    methods: {
        proceed(){
            if(this.type_of_payment !== 'linkbiz_portal') {
                this.$emit('continue', this.type_of_payment)
            } else {
                this.$emit('proceedToPayment')
            }
            
        }
    }
}
</script>