<template>
    <v-card width="800" class="overflow-x-hidden">
        <v-container fluid  >
            <section class="d-flex flex-wrap justify-space-between align-center mb-5">
                <section class="primary-font primary--text fw600 f17">
                    How to pay
                </section>
                <section class="text-right">
                    <v-btn icon @click="$emit('close')">
                        <v-icon small>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </section>
            </section>
            
            <section class="f14 secondary-2--text">
                <v-row dense v-for="(item, i) in steps" :key="i">
                    <v-col cols="3">
                        <v-chip
                            class="fw500"
                            color="red lighten-5"
                            text-color="red"
                            pill
                        >
                            Step {{ i + 1 }}
                        </v-chip>
                    </v-col>
                    <v-col>
                        <div v-if="i === 0" class="mb-1">
                            {{ getStepOne }}
                        </div>
                        <div v-else v-for="(text, j) in item.text" :key="j" class="mb-1">
                            {{ text }}
                        </div>
                        <div v-if="item.listHeader" class="mb-1">
                            {{ item.listHeader }}
                        </div>
                        <ol>
                            <li v-for="(list, k) in item.list" :key="k">
                                {{ list }}
                            </li>
                        </ol>
                    </v-col>
                </v-row>
            </section>
        </v-container>
        <v-container fluid class="d-flex flex-row justify-space-evenly">
            <v-btn dense text @click="$emit('back')">
                Back
            </v-btn>
            <v-spacer />
            <v-btn outlined dense color="primary" @click="$emit('continue')">
                Continue
            </v-btn>
        </v-container>
    </v-card>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex"

export default {
    props: ['chosen'],
    data: () => ({
        steps: [
            {
                text: [],
                listHeader: null,
                list: []
            },
            {
                text: [
                    'For Over-the-Counter payment, you have the option to pay at the CSC Cashier or through the nearest LBP Servicing Branch.',
                ],
                listHeader: 'Ensure that the payment is made payable or credited to the following bank account:',
                list: [
                    'Account Name: CSC Training Fund',
                    'Type of Account: Current Account',
                    'Account No.: 3122-1001-37',
                    'Bank Branch: LBP-Batasan Branch'
                ]
            },
            {
                text: [],
                listHeader: 'Payment methods include:',
                list: [
                    'Check Payment/Deposit payable to CSC Training Fund',
                    'Direct Payment to CSC-CO via cash or check',
                    'Bank Deposit',
                    'LDDAP-ADA Payment (List of Due and Demandable Accounts Payable-Advice-to-Debit Account)',
                    'Landbank to Landbank Fund Transfer (via iAccess)',
                    'Other banks to Landbank (via online/mobile banking)',
                    'LBP-eMDS'
                ]
            },
            {
                text: [
                    'When uploading your Proof of Payment, kindly use the following FILENAME format:',
                    'COURSE TITLE & DATE OF CONDUCT_LAST NAME, FIRST NAME',
                    'Example: CSILS-Nov24_2023_DELA CRUZ, JUAN.'
                ],
                listHeader: null,
                list: []
            },
        ]
    }),
    computed: {
        ...mapState({
            errors: (state) => state.errors,
        }),

        getStepOne(){
            return this.chosen === 'YES' 
                ? 'If the agency is covering the cost, please answer "YES" and upload the Nomination Form and Proof of Payment. In the absence of Proof of Payment, please upload a Commitment/Promissory Letter indicating the date of payment. The system will recognize it as a Pending Transaction, and as for the approval of Admin Team.'
                : 'If the answer is "NO," proceed to payment, and a Landbank Portal will appear. Follow the provided steps.'
        }
    },
    watch: {
    },
    methods: {
        ...mapActions('usr', ['courseCheckout']),

    }
    
}
</script>