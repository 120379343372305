var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"overflow-x-hidden",attrs:{"width":"800"}},[_c('section',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"icon":""},on:{"click":() => {
                _vm.remove()
                _vm.$emit('close')
            }}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-container',{staticClass:"primary-font fw600 f16",attrs:{"fluid":""}},[_c('FormLabel',{attrs:{"label":'UPLOAD PROOF OF PAYMENT *'}}),_c('v-container',{staticClass:"bulk-border mt-2 d-flex flex-column justify-center",on:{"dragover":_vm.dragover,"dragleave":_vm.dragleave,"drop":_vm.dropProofOfPayment}},[_c('div',{staticClass:"d-flex text-center flex-column"},[_c('v-icon',{attrs:{"color":"rgba(63, 93, 208, 0.12)","size":"68"}},[_vm._v("mdi-cloud-upload-outline")]),_c('div',{staticClass:"secondary--text f12 poppins"},[_c('a',{staticClass:"primary--text text-decoration-none",attrs:{"href":"#"},on:{"click":function($event){return _vm.$refs.proof_of_payment.click()}}},[_vm._v(" Browse ")]),_vm._v(" or drag file here ( .pdf, .docx, .doc, .jpg, .jpeg, .png ) ")])],1)]),(_vm.proofOfPayment)?_c('v-container',{staticClass:"d-flex justify-space-between align-center my-3"},[_c('div',{staticStyle:{"width":"250px"}},[_c('div',{staticClass:"roboto fw600 f12",staticStyle:{"margin-bottom":"-8px"}},[_vm._v(" "+_vm._s(_vm.proofOfPayment.name)+" ")]),_c('span',{staticClass:"roboto fw500 f10 secondary--text"},[_vm._v(" "+_vm._s((_vm.proofOfPayment.size / 1000).toFixed(2))+" KB ")])]),_c('v-spacer'),(_vm.loading)?_c('v-progress-linear',{staticStyle:{"width":"120px"},attrs:{"indeterminate":"","color":"success","height":"7","rounded":""}}):_vm._e(),(!_vm.submitLoading)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.remove}},[_c('v-icon',{attrs:{"size":"18","color":"danger-1"}},[_vm._v("mdi-close")])],1):_vm._e()],1):_vm._e()],1),_c('v-container',[(_vm.msg.show)?_c('alert',{attrs:{"show":_vm.msg.show,"text":_vm.msg.text,"type":_vm.msg.type}}):_vm._e()],1),_c('section',{staticClass:"d-flex flex-row justify-space-evenly pa-3"},[_c('v-btn',{attrs:{"text":""},on:{"click":() => {
                _vm.remove()
                _vm.$emit('back')
            }}},[_vm._v(" GO BACK ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.loading ? !_vm.proofOfPayment : true,"loading":_vm.submitLoading},on:{"click":function($event){return _vm.submitProofOfPayment()}}},[_vm._v(" SUBMIT ")])],1),_c('input',{ref:"proof_of_payment",staticClass:"d-none",attrs:{"type":"file","accept":".pdf,.docx,.doc,.png,.jpg,.jpeg"},on:{"change":_vm.acceptProofOfPayment}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }